import { requestApi, requestOAuth } from '../utils/request';
import {
  LoginData,
  User,
  InfoAccountToUpdate,
  InfoLanguageToUpdate,
  ResetPasswordData,
  RenewPasswordData,
  AuthorizeSonosData,
} from './SoundSuitServiceTypes';

/** Auth */
export const login = (data: LoginData) => requestOAuth<any>(false, data.email, data.password);
export const logout = () => requestApi<boolean>('/logout', 'POST', false, true);
export const register = (data: any) => requestApi<any>('/register', 'POST', data, false);
export const refreshToken = () => requestOAuth<null>(true);
export const resetPassword = (data:ResetPasswordData) => requestApi<any>('/reset_password', 'POST', data, true);
export const newPassword = (data:RenewPasswordData) => requestApi<any>('/reset_password/password', 'POST', data, true);
export const authorizeSonos = (data: AuthorizeSonosData) => requestApi<any>('/sonos/auth', 'POST', data, false);

/** Account */
export const loadUserProfile = () => requestApi<User>('/userinfo', 'GET', false, true);
export const updateUserProfile = (data: InfoAccountToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
export const updateLanguageProfile = (data: InfoLanguageToUpdate) => requestApi<string>('/user/settings', 'POST', data, true);
