import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';
import ENVS from '../../config';
import { Audio } from 'react-loading-icons';

import { redText } from '../../config/colors';
import { login } from '../../services/SoundSuitService';
import { useStore } from '../../store';
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';

// import Components
import GoogleButton from '../molecules/GoogleButton';

interface Props {

};

const SignInScreen: React.FC<Props> = props => {

  async function handleNext() {
    setLoading(true);
    if (validate(email) && typeof password !== "undefined" && password !== "") {
      setError(false);
      setErrorTrialEnded(false);
      try {
        const resultLogin = await login({email, password});
        if (resultLogin.success) {

          setUserSession(resultLogin.data);

          if (Boolean(loginFromCheckout) && planId) {
            navigate(`/subscribe?plan_id=${planId}`);
            return;
          }

          try {
            const resUserInfo = await fetch(`${ENVS.API_URL}/userinfo`, {
              method: "GET",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${resultLogin?.data?.token || ""}`
              }
            });
    
            const resData = await resUserInfo.json();
            const data = resData;
            if (!data) {
              throw new Error("No DATA retrived from userinfo");
            }

            const alreadySeenOnBoarding: boolean = data?.firstOnboardingDone || false;
            if (!data.isSubAccount && !alreadySeenOnBoarding) {
              navigate('/onboarding/step1');
            } else {
              navigate('/redirect', { state : {...resultLogin.data}});
            }
          } catch (error) {
            console.log("ERROR RETRIEVE INFOS : ", error)
          }
        } else {
          if (resultLogin?.message === "trial ended") {
            setErrorTrialEnded(true);
          }
          setError(true);
        }
      } catch (error) {
        console.log("ERROR SIGNIN : ", error);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const loginFromCheckout = searchParams.get('login_from_checkout');
  const planId = searchParams.get('plan_id');

  const setUserSession = useStore.getState().setUserSession;
  const [error, setError] = useState<boolean>(false);
  const [errorTrialEnded, setErrorTrialEnded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>{t("Register.titleSignIn")}</Title>

      <BlocText>
        <SubText>{t("Register.notMember")} <SubTextRed onClick={() => navigate('/')}>{t("Register.freeTrial")}</SubTextRed></SubText>
      </BlocText>
     
      <Form>
        <TitleForm>{t('Register.email')}</TitleForm>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('Register.email')}
          style={{marginBottom: "20px"}}
        />
        <TitleForm>{t('Register.passwordLabel')}</TitleForm>
        <InputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('Register.passwordLabel')}
          />
          <IconButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            {showPassword ? <EyeInvisibleOutlined style={{ fontSize: '18px'}} /> : <EyeOutlined style={{ fontSize: '18px'}} />}
          </IconButton>
        </InputWrapper>
      </Form>
      <ForgotPassword onClick={() => navigate('/forgot-password')}>
        {t('Auth.forgotPassword')}
      </ForgotPassword>
      <LoginButton 
          onClick={handleNext} 
          disabled={email.length === 0}
        >
          {loading ? <Audio stroke={redText} height="18px" /> : t('Register.signinLabelButton')}
      </LoginButton>

      {error && (
        <ErrorText>
         {t('Auth.emailUnknow')}
        </ErrorText>
      )}
      {errorTrialEnded && (
        <ErrorText>
         {t('Auth.trialEnded')}
        </ErrorText>
      )}
      
      {/*
      <Separator>
        {t('Register.orContinue')}
      </Separator>

      <GoogleButton />
      */}

    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Logo = styled.img`
  width: 45px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const BlocText = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SubText = styled.span`
  font-size: 13px;
  color: #808EAF;
`;

const SubTextRed = styled.span`
  font-weight: 600;
  color: ${redText};
  cursor: pointer;

  &:hover {
    color: #e55e5e;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #808EAF44;
  border-radius: 10px;
  font-size: 14px;
  padding-right: 40px;
`;

const IconButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #808EAF;

  &:hover {
    color: #666;
  }
`;

const Form = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const TitleForm = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
`;

const ForgotPassword = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 13px;
  color: #ff6b6b;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    color: #e55e5e;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF6259;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;

  &:hover {
    background-color: #EE6259;
  }
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: #ff6b6b;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
`;


const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0px 20px 0px;
  font-weight: 500;
  color: #808EAF;
  
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 1.5px solid #808EAF44;
    margin: 0 10px;
  }
`;

export default SignInScreen;