import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import ENVS from '../../config';

import { weakRed} from '../../config/colors';
// import Components
import Text from '../atoms/Text';

interface Props {
};

const VerifyScreen: React.FC<Props> = props => {

  const  { token } = useParams();

  const [isVerify, setIsVerify] = useState<boolean | null>(null);


  useEffect(() => {

    async function verifyToken(token) {
      try {
        const res = await fetch(`${ENVS.API_URL}/register/verify_token/${token}`, {
          method:"POST"
        });
  
        if (res.status === 200) {
          setIsVerify(true);
        } else {
          setIsVerify(false);
        }
      } catch (error) {
        setIsVerify(false);
      }
    }

    if (token) {
      verifyToken(token);
    }

  }, [token]);

  useEffect(() => {

    if (isVerify) {
      setTimeout(() => {
        window.location.replace("https://soundsuit.fm/get-app");
      }, 2000);
    }

  }, [isVerify]);

  return (
    <Container>
      {isVerify === null && (
        <Text align='center'>Verifying your email...</Text>
      )}
      {isVerify && (
        <Text align='center'>All good!</Text>
      )}
      {!isVerify && (
        <Text align='center'>Impossible to confirm your email. Please contact the support.</Text>
      )}
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
`;
const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${weakRed};
  border-radius: 25px;
  display: flex;
  margin-bottom: 15px;
`;
const Row = styled.div<{center?: boolean}>`
  width: 70%; 
  display: flex;
  align-items: center;
  justify-content: ${({center}) => center ? 'center':'flex-start'};
`;
const Gap = styled.div`
  width: 100%;
  height: 10px;
`;
const BigGap = styled.div`
  width: 100%;
  height: 40px;
`;

export default VerifyScreen;