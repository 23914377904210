import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';

import { strongRed, lightgray, black} from '../../config/colors';
// import components
import Text from './Text';

interface Props {
  label: string;
  Icon?: React.ReactElement;
  placeholder?: string;
  value: string;
  handleChange: (arg: string) => void;
  password?: boolean;
  FixedIcon?: React.ReactElement;
  disabled?: boolean;
  [arg: string]: any;
};

const InputText: React.FC<Props> = props => {

  function onHandleChange(event) {
    handleChange(event.target.value);
  }

  function handlePressIcon(){
    if(password) {
      setHide(h => !h)
    } else {
      handleChange('');
    }
  }

  const {
    label,
    Icon,
    placeholder,
    value,
    handleChange,
    password,
    FixedIcon,
    disabled = false,
    ...rest
  } = props;

  const [ isFocus, setIsFocus] = useState<boolean>(false);
  const [ hide, setHide] = useState<boolean>(password);

  return (
    <Container focus={isFocus}>
      <Text size="small" color={isFocus ? black : lightgray} >{label}</Text>
      <Row>
        {password ? (
          <Input.Password
            placeholder={placeholder}
            iconRender={visible => (!visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
            value={value}
            onChange={onHandleChange}
            bordered={false}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
        ):(
          <>
            <Input
              value={value}
              onChange={onHandleChange}
              placeholder={placeholder}
              bordered={false}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              disabled={disabled}
            />
            <IconButton>
              {FixedIcon ? (
                React.cloneElement(FixedIcon, {
                  style: { tintColor: isFocus ? strongRed : lightgray, }
                })
              ) : (
                Icon && React.cloneElement(Icon, {
                  color: isFocus ? strongRed : lightgray,
                  size: 22,
                  onClick: handlePressIcon
                })
              )}
            </IconButton>
          </>
        )}
      </Row>
    </Container>
  );
}

const Container = styled.div<{focus: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 10px;
  border-bottom: ${({focus}) => focus ? `solid 2px ${strongRed}` : `solid 1px ${lightgray}`};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const IconButton = styled.div`
  padding-right: 12px;
  cursor: pointer;
`;

export default InputText;