import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { weakRed, redText, linkBlue } from '../../config/colors';
// import Components
import { useLocation } from 'react-router-dom';
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";

interface Props {

};

interface LocationState {
    email?: string;
}

const ThankScreen: React.FC<Props> = props => {

  function handleRedirection(url: string) {
    window.open(url, '_blank').focus();
  }

  const location = useLocation();
  const { email } = (location.state as LocationState) || {};
  const [t] = useTranslation();

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>{t('Register.thankLabel')}</Title>
      <Square>
        <CheckOutlined style={{ fontSize: '38px', margin: 'auto', color: redText }}  />
      </Square>
      
      <Text>{t('Register.sendEmailLabel')} <TextEmail>{email}</TextEmail>.</Text>
      <Text>{t('Register.explanationLabel')}</Text>

      <Button 
          onClick={() => handleRedirection("https://soundsuit.fm/get-app")}
        >
          {t('Register.buttonRedirect')}
      </Button>

       <Separator>
        {t('Register.orLabel').toLowerCase()}
      </Separator>

      <ButtonWhite 
          onClick={() => handleRedirection("https://soundsuit.fm/sonos-for-business")}
        >
          {t('Register.addTo') + " Sonos"}
      </ButtonWhite>

      <ButtonWhite
          onClick={() => handleRedirection("https://soundsuit.fm/loxone-audio-for-business")}
        >
          {t('Register.addTo') + " Loxone"}
      </ButtonWhite>


    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
`;


const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
`;

const Logo = styled.img`
  width: 45px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 25px;
  margin-top: 0;
`;

const Text = styled.p`
  width: 100%;
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
`;

const TextEmail = styled.span`
  font-size: 13px;
  color: ${linkBlue};
`;

const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${weakRed};
  border-radius: 25px;
  display: flex;
  margin-bottom: 30px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF6259;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 0px;

  &:hover {
    background-color: #EE6259;
  }
`;

const ButtonWhite = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  color: #FF6259;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #FF6259;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 0px;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0px 5px 0px;
  font-weight: 500;
  color: #808EAF;
  
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 1.5px solid #808EAF44;
    margin: 0 10px;
  }
`;

export default ThankScreen;