import { create } from 'zustand';
import type { BusinessType, Gender, Styles, Genres } from '../services/SoundSuitServiceTypes';

type UserSession = {
  token?: string;
  refreshToken?: string;
  expiresAt?: string;
  refreshTokenExpiresAt?: string;
};

type OnBoardingInfos = {
  business:BusinessType;
  gender: Gender;
  age: [number, number];
  styles: Styles[];
  genres: Genres[];
  smartGenres: boolean;
}

type StoreContent = {
  language?: 'fr' | 'en' | 'de' | 'it' | 'es';
  from?: 'web' | 'electron' | 'ios' | 'android' | 'loxone';
  redirectId?: string;
  setData: any;
  userSession?: UserSession;
  onBoardingInfos: OnBoardingInfos;
  setUserSession: (userSession: UserSession) => void;
  setOnboardingInfos: (infos: any) => void;
}

export const useStore = create<StoreContent>((set) => ({
  language: undefined,
  from: undefined,
  redirectId: undefined,
  userSession: undefined,
  onBoardingInfos: {} as OnBoardingInfos,
  setData: (language, from, redirectId) => set({ language, from, redirectId }),
  setUserSession: (userSession: UserSession) => set({userSession}),
  setOnboardingInfos: (infos: any) => set(state => ({ onBoardingInfos: { ...state.onBoardingInfos, ...infos } }))
}));