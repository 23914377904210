import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ENVS from '../../config';
import { useNavigate } from 'react-router-dom';

import { redText } from '../../config/colors';
import type { Genres, ToggleLabels } from '../../services/SoundSuitServiceTypes';
import { useStore } from '../../store';
// import components
import GenreOptions from '../molecules/GenreOptions';
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

export interface OnBoardingSettings {
  business_type: string;
  customer_age: string[];
  styles: string[];
  genres: Genres[];
  bpmRange?: string;
  languages: string[];
}

const OnBoardingStep3 = () => {
  async function handleSubmit() {
    try {
      if (userSession) {
        const { business, age, styles } = onBoardingInfos;

        let genresData: Genres[] = selectedGenres.slice();
        if (smartGenres) {
          genresData = ['pop', 'dancehall', 'lounge'];
        }
        const dataToSend: OnBoardingSettings = {
          business_type: business,
          customer_age: [`<${age[1]}`],
          styles: styles,
          genres: genresData,
          languages: ["english"]
        };
        await fetch(`${ENVS.API_URL}/user/onboarding`, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userSession.token}`
          },
          body: JSON.stringify(dataToSend)
        });
        await fetch(`${ENVS.API_URL}/user/settings`, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userSession.token}`
          },
          body: JSON.stringify({ firstOnboardingDone: true })
        });
        navigate('/redirect', { state: userSession });
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const onBoardingInfos = useStore.getState().onBoardingInfos;
  const userSession = useStore.getState().userSession;

  const [selectedGenres, setSelectedGenres] = useState<(Genres)[]>([]);
  const [smartGenres, setSmartGenres] = useState<boolean>(false);

  return (
    <Container>
      <Header>
        <Text size="small" color={redText}>{`${t('onBoarding.step')} 3`}</Text>
        <Title>{t('onBoarding.step3Title')}</Title>
      </Header>
      <Content>
        <GenreOptions
          selected={selectedGenres}
          setSelected={setSelectedGenres}
          smartGenres={smartGenres}
          setSmartGenres={setSmartGenres}
        />
      </Content>
      <Footer>
        <ButtonText
          onHandleClick={handleSubmit}
          colorType='red'
          width="80%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('onBoarding.next')}
        </ButtonText>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 66px;
  padding-bottom: 50px;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled(Text)`
  margin-top: 12px;
  font-size: 22px;
  padding: 0 60px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default OnBoardingStep3;