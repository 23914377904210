import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";

import { redText } from '../../config/colors';
import { resetPassword } from '../../services/SoundSuitService';
// import Components
import Text from '../atoms/Text';

interface Props {

};

const ForgotPassword: React.FC<Props> = props => {

  async function handleNext() {
    setLoading(true);
    if (validate(email)) {
      setError(false);
      const resultReset = await resetPassword({email});
      if (resultReset === '') {
        navigate(`/new-password`, { state: { email }});
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>Forgot your password?</Title>
      <Description>Enter your email address and we will send you instructions to reset your password.</Description>
      <Form>
        <TitleForm align="center">Email address</TitleForm>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address"
        />
      </Form>
      <WrapperButton>
        <SaveButton 
          onClick={handleNext} 
          disabled={email.length === 0}
        >
          Send reset link
        </SaveButton>
        {error && (
          <WrapperEmail>
            <Text color={redText} size="small" >{t('Auth.noEmailFound')}</Text>
          </WrapperEmail>
        )}
      </WrapperButton>
      <BackToLogin onClick={() => navigate("/signin")}>
        &lt; Back to login
      </BackToLogin>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Logo = styled.img`
  width: 45px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
`;


const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #808EAF44;
  border-radius: 10px;
  font-size: 14px;
`;

const Form = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`;

const TitleForm = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
`;

const SaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#FF6259')};
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 10px;
  margin-bottom: 25px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#EE6259')};
  }
`;

const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;
const WrapperEmail = styled.div`
  width: 100%;
`;

const BackToLogin = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #ff6b6b;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    color: #e55e5e;
  }
`;
export default ForgotPassword;