import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from '../../utils/i18n';

import { Genres, ToggleLabels } from '../../services/SoundSuitServiceTypes';
import { idToLabel, IdToLabel} from '../../config/hardData';
import pop from '../../assets/images/pop.jpg';
import rock from '../../assets/images/rock.jpg';
import hit from '../../assets/images/hits.jpg';
import jazz from '../../assets/images/jazz.jpg';
import soul from '../../assets/images/soul.jpg';
import acoustic from '../../assets/images/acoustic.jpg';
import hipHop from '../../assets/images/hipHop.jpg';
import disco from '../../assets/images/disco.jpg';
import dance from '../../assets/images/dance.jpg';
import lounge from '../../assets/images/lounge.jpg';
import rNb from '../../assets/images/rB.jpg';
import dancehall from '../../assets/images/dancehall.jpg';
import chillout from '../../assets/images/chillout.jpg';
import folk from '../../assets/images/country.jpg';
import christmas from '../../assets/images/christmas.jpg';
import nature from '../../assets/images/nature.jpg';
import fitness from '../../assets/images/fitness.jpg';
import world from '../../assets/images/world.jpg';
import reggae from '../../assets/images/reggae.jpg';
import jazzPop from '../../assets/images/jazz-pop.jpg';
import electronic from '../../assets/images/electronic.jpg';
import bossaNova from '../../assets/images/bossa-nova.jpg';
import workoutHipPop from '../../assets/images/workout-hiphop.jpg';
import workoutPop from '../../assets/images/workout-pop.jpg';
import workoutRock from '../../assets/images/workout-rock.jpg';
import Classical from '../../assets/images/classical.jpg';
import JazzPiano from '../../assets/images/jazz-pianobar.jpg';
import RockCountry from '../../assets/images/rock-country.jpg';
import JazzBeats from '../../assets/images/jazz-beats.jpg';
// import components
import CardOption from '../molecules/CardOption';

export interface ListGenres {
  label: Genres;
  img: string;
}

export const listGenres: ListGenres[] = [
  {label: 'pop', img: pop},
  {label: 'rock', img: rock},
  {label: 'hit', img: hit},
  {label: 'jazz', img: jazz},
  {label: 'soul', img: soul},
  {label: 'acoustic', img: acoustic},
  {label: 'hip-hop', img: hipHop},
  {label: 'disco', img: disco},
  {label: 'dance', img: dance},
  {label: 'lounge', img: lounge},
  {label: 'r&b', img: rNb},
  {label: 'dancehall', img: dancehall},
  {label: 'chillout', img: chillout},
  {label: 'folk', img: folk},
  {label: 'christmas', img: christmas},
  {label: 'nature', img: nature},
  {label: 'fitness', img: fitness},
  {label: 'world', img: world},
  {label: 'reggae', img: reggae},
  {label: 'jazz-pop', img: jazzPop},
  {label: 'electronic', img: electronic},
  {label: 'bossa-nova', img: bossaNova},
  {label: 'workout-pop', img: workoutPop},
  {label: 'workout-rock', img: workoutRock},
  {label: 'workout-hip-hop', img: workoutHipPop},
  {label: 'classical', img: Classical},
  {label: 'jazz-piano', img: JazzPiano},
  {label: 'rock-country', img: RockCountry},
  {label: 'jazz-beats', img: JazzBeats},
];

interface Props {
  selected: (Genres|ToggleLabels)[];
  setSelected: React.Dispatch<React.SetStateAction<(Genres|ToggleLabels)[]>>;
  smartGenres: boolean;
  setSmartGenres: React.Dispatch<React.SetStateAction<boolean>>;
};

const GenreOptions: React.FC<Props> = props => {

  const {
    selected,
    setSelected,
    smartGenres,
    setSmartGenres,
  } = props;

  const [ t ] = useTranslation();
  const lang = i18n.language;
  const currentIdToLabel: IdToLabel = useMemo(() => idToLabel(), [lang]);
  const listGenresSorted = useMemo(() => listGenres.sort((a, b) =>  currentIdToLabel[a.label].localeCompare(currentIdToLabel[b.label])), [listGenres]);

  return (
    <WrapperOptions>
      <WrapperCard>
        {listGenresSorted.map(({label, img}) => (
          <CardOption
            key={label}
            label={currentIdToLabel[label]}
            image={img}
            selected={selected.includes(label) && !smartGenres}
            handlePress={() => {
              if(smartGenres) {
                setSmartGenres(false);
                setSelected([label]);
              } else {
                if (selected.includes(label)) {
                  const newTab: (Genres | ToggleLabels)[] = selected.filter(e => e !== label);
                  setSelected(newTab);
                } else {
                  const newTab = selected.slice();
                  newTab.push(label);
                  setSelected(newTab);
                }
              }
            }}
          />
        ))}
      </WrapperCard>
    </WrapperOptions>
  );
}

const WrapperOptions = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 10px;
`;
const WrapperCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export default GenreOptions;