import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loading-icons';

import { redText } from '../../config/colors';
import ENVS from '../../config';
import { useStore } from '../../store';
// import Components

export type CheckoutRes = {
  id: string;
  type: string;
  url: string;
  state: string;
  embed: boolean;
  created_at: number;
  expires_at: number;
  object: string;
  updated_at: number;
  resource_version: number;
};

interface Props {
};

const VerifyScreen: React.FC<Props> = props => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get('plan_id');
  const tkn = searchParams.get('token');

  const userSession = useStore(state => state.userSession);
  const [urlCheckout, setUrlCheckout] = useState<string>('');

  useEffect(() => {
    async function requestForUrl(planId: string, token: string) {

      const res = await fetch(`${ENVS.API_URL}/billing/checkout`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({planId}),
      });
      if (!res.ok) {
        navigate('/');
      }
      const data = await res.json();
      setUrlCheckout(data.url);
    }
    if (planId && tkn && !userSession) {
      requestForUrl(planId, tkn);
    } else if (planId && !tkn && !userSession) {
      navigate(`/signin?plan_id=${planId}&login_from_checkout=true`);
    } else if (planId && !tkn && userSession) {
      requestForUrl(planId, userSession.token); 
    } else {
      navigate('/');
    }
  }, [planId, tkn, userSession, navigate]);


  return (
    <Container>
      {!urlCheckout && (
        <WrapperLoading>
          <TailSpin stroke={redText} height="2em" />
        </WrapperLoading>
      )}
      {urlCheckout && (
        <iframe
          title='checkout'
          src={urlCheckout}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        ></iframe>
      )}
    </Container>
  );
}


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`;
const WrapperLoading = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default VerifyScreen;