import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { redText } from '../../config/colors';
import type { Gender, Styles } from '../../services/SoundSuitServiceTypes';
import { useStore } from '../../store';
// import components
import TargetOptions from '../molecules/TargetOptions';
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

const OnBoardingStep2 = () => {

  function handleSubmit() {
    setOnboardingInfos({
      gender,
      age,
      styles
    });
    navigate('/onboarding/step3');
  }

  function handleSelectStyles(id: Styles) {
    const copyStyle: Styles[] = styles.slice();
    if (styles.includes(id)) {
      const index: number = copyStyle.findIndex((s) => s === id);
      copyStyle.splice(index, 1);
    } else {
      copyStyle.push(id);
    }
    setStyles(copyStyle);
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const setOnboardingInfos = useStore.getState().setOnboardingInfos;
  const [gender, setGender] = useState<Gender>("manWoman");
  const [age, setAge] = useState<[number, number]>([0, 50]);
  const [styles, setStyles] = useState<Styles[]>(['mainstream', 'trendy']);

  return (
    <Container>
      <Header>
        <Text size="small" color={redText}>{`${t('onBoarding.step')} 2`}</Text>
        <Title>{t('onBoarding.step2Title')}</Title>
      </Header>
      <Content>
        <TargetOptions
          gender={gender}
          setGender={setGender}
          styles={styles}
          setStyles={handleSelectStyles}
          age={age}
          setAge={setAge}
        />
      </Content>
      <Footer>
        <ButtonText
            onHandleClick={handleSubmit}
            colorType='red'
            width="80%"
            height={50}
            borderRadius="big"
            fontSize={12}
          >
            {t('onBoarding.next')}
        </ButtonText>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 66px;
  padding-bottom: 50px;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled(Text)`
  margin-top: 12px;
  font-size: 22px;
  padding: 0 60px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default OnBoardingStep2;