import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { redText } from '../../config/colors';
import type { BusinessType } from '../../services/SoundSuitServiceTypes';
import { useStore } from '../../store';
// import components
import TypeOptions from "../molecules/TypeOptions";
import Text from '../atoms/Text';
import ButtonText from '../atoms/ButtonText';

const OnBoardingStep1 = () => {

  function handleSubmit() {
    setOnboardingInfos({business: selectedType});
    navigate('/onboarding/step2');
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const setOnboardingInfos = useStore.getState().setOnboardingInfos;
  const [selectedType, setSelectedType] = useState<BusinessType>('design_store');

  return (
    <Container>
      <Header>
        <Text size="small" color={redText}>{`${t('onBoarding.step')} 1`}</Text>
        <Title>{t('onBoarding.step1Title')}</Title>
      </Header>
      <Content>
        <TypeOptions selected={selectedType} setSelected={setSelectedType} />
      </Content>
      <Footer>
        <ButtonText
          onHandleClick={handleSubmit}
          colorType="red"
          width="80%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('onBoarding.next')}
        </ButtonText>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 66px;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled(Text)`
  margin-top: 12px;
  font-size: 22px;
  padding: 0 60px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default OnBoardingStep1;