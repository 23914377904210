import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";
import { black } from '../../config/colors';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
  email: string;
}

const SuccessPassword: React.FC = () => {
  const location = useLocation();
  const state = location.state as Props | undefined;
  const email = state?.email || "";

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>Success</Title>
      <CheckOutlined style={{ fontSize: '78px', margin: 'auto', marginBottom: '50px', color: black }} />
      <Description>
        You just saved a new password for your Soundsuit account <UserEmail>{email}</UserEmail>.
      </Description>
      <Description>
        Now you can go back to your app and log in with the new password.
      </Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
`;

const UserEmail = styled.span`
  color: #007aff;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 30px;
`;

export default SuccessPassword;
