import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// locales
import en from './locales/en.json';
import fr from './locales/fr.json';
import de from './locales/de.json';
import it from './locales/it.json';
import es from './locales/es.json';

const options = {
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    wait: false
  },
  lng: 'en',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
    de: {
      translation: de,
    },
    it: {
      translation: it,
    },
    es: {
      translation: es,
    },
  },
  fallbackLng: 'en',

};

i18n
  .use(initReactI18next)
  .init(options);

export default i18n;
