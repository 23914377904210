import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import i18n from '../../utils/i18n';
import { redText, weakRed } from '../../config/colors';

interface Props {
  lang: 'fr' | 'en' | 'de' | 'it' | 'es';
}

const languageOptions = [
  { value: 'en', label: 'EN' },
  { value: 'fr', label: 'FR' },
  { value: 'it', label: 'IT' },
  { value: 'de', label: 'DE' },
  { value: 'es', label: 'ES' },
];

const CompactLanguageSelector: React.FC<Props> = ({ lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (value: Props['lang']) => {
    setSelectedLang(value);
    i18n.changeLanguage(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <LanguageButton onClick={toggleDropdown} aria-haspopup="listbox" aria-expanded={isOpen}>
        {languageOptions.find(option => option.value === selectedLang)?.label}
        <ArrowIcon isOpen={isOpen} />
      </LanguageButton>
      {isOpen && (
        <DropdownList role="listbox">
          {languageOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleLanguageChange(option.value as Props['lang'])}
              isSelected={option.value === selectedLang}
              role="option"
              aria-selected={option.value === selectedLang}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const LanguageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  padding: 8px 10px;
 
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  background: white;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.10);
  }
`;

const ArrowIcon = styled.span<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.2s ease-in-out;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  margin: 4px 0 0;
  padding: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const DropdownItem = styled.li<{ isSelected: boolean }>`
  list-style: none;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  color: ${({ isSelected }) => (isSelected ? redText : '#333')};
  background: ${({ isSelected }) => (isSelected ? weakRed : 'white')};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${weakRed};
  }
`;

export default CompactLanguageSelector;