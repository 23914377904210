import React from 'react';
import styled from 'styled-components';
import { AiOutlineCheck } from "react-icons/ai";
import hexToRgba from 'hex-to-rgba';

import { buttonRed } from '../../config/colors';
// import components
import Text from '../atoms/Text';

interface Props {
  label: string;
  image: string;
  handlePress: () => void;
  selected: boolean;
  bold?: boolean;
};

const CardOption: React.FC<Props> = props => {

  const {
    label,
    image,
    handlePress,
    selected,
    bold = false
  } = props;

  return (
    <Container onClick={handlePress}>
      {selected && (
        <Selected>
          <AiOutlineCheck name="check" size={28} color="white" />
        </Selected>
      )}
      <CardImage src={image} alt={label} />
      <Text align="center" bold={bold} size="small" >{label}</Text>
    </Container>
  );
};

const Container = styled.button`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80px;
  height: 100px;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
const CardImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 13px;
  margin-bottom: 10px;
  z-index: 0;
`;
const Selected = styled.div`
  background-color: ${hexToRgba(buttonRed, '0.4')};
  width: 60px;
  height: 60px;
  border-radius: 13px;
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardOptionMemoized = React.memo(CardOption);

export default CardOptionMemoized;