import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { authorizeSonos } from '../../services/SoundSuitService';
import ButtonText from '../atoms/ButtonText';
import InputText from '../atoms/InputText';

interface Props {

}

// TODO: Most of this part is copied from 'CredentialsScreen.tsx' I will need to refactor this later
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
`;

const WrapperError = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`;

const Row = styled.div<{ center?: boolean }>`
  width: 110%; 
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => center ? 'center' : 'space-between'};
`;

const SonosLogin: React.FC<Props> = props => {
  // TODO: Use
  const [t] = useTranslation();
  const [searchParams, _] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);

  const sonosLinkCode = searchParams.get('linkCode');

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await authorizeSonos({ email, password, linkCode: sonosLinkCode })
      setFinished(true)
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Container>
      {finished ? (
        <Container>
          Authentication successful you can go back to the app
        </Container>
      ) : (
        <>
          {!sonosLinkCode &&
            <WrapperError>
              Error
            </WrapperError>
          }
          <Row>
            <InputText
              value={email}
              handleChange={setEmail}
              label={"Email"}
              placeholder="user@gmail.com"
            />
          </Row>
          <Row>
            <InputText
              password={true}
              value={password}
              handleChange={setPassword}
              label={"Password"}
              type="password"
            />
          </Row>
          <ButtonText
            onHandleClick={handleSubmit}
            colorType='red'
            width="100%"
            height={50}
            borderRadius="big"
            fontSize={14}
            disabled={loading}
          >
            Authorize Sonos
          </ButtonText>
        </>
      )}
    </Container>
  )
}

export default SonosLogin;
