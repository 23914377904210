import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";
import { Audio } from 'react-loading-icons';
import { register } from '../../services/SoundSuitService';
import {
  URL_PRIVACY,
  URL_TERMS
} from '../../config/hard';
import { redText } from '../../config/colors';
import GoogleButton from '../molecules/GoogleButton';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  
};

const StartSignUpScreen: React.FC<Props> = props => {

  async function handleSubmit() {
    if(validate(email) && firstName !== "" && lastName !== "" && password !== "" && password.length >= 6 && agreed) {
      setLoading(true);
      setError(false);
      const registerRes = await register({
        company_name: "",
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        trackingId: null,
        accept_tos: agreed,
        accept_receive_info: agreed
      });
      if(registerRes === "") {
        navigate('/thank-you', { state: { email } });
      } else {
        setError(true);
        setTypeError(registerRes);
        console.log('ERROR: ', registerRes);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  function openNewTab(url: string) {
    window.open(url, '_blank').focus();
  }

  const [t] = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreed, setAgreed] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [typeError, setTypeError] = useState<string>('');


  return (
   <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>{t("Register.title")}</Title>

      <BlocText>
        <SubText>{t("Register.AlreadyLabel")} <SubTextRed onClick={() => navigate('/signin')}>{t("Register.logHereLabel")}</SubTextRed></SubText>
      </BlocText>

      <Form>
        <TitleForm>{t('Register.email')}</TitleForm>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('Register.emailPlaceholder')}
        />

        <Row>
          <div>
            <TitleForm>{t('Register.firstnameLabel')}</TitleForm>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t('Register.firstnameLabel')}
            />
          </div>
          <div>
            <TitleForm>{t('Register.lastnameLabel')}</TitleForm>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t('Register.lastnameLabel')}
            />
          </div>
        </Row>

        <TitleForm>{t('Register.passwordLabel')}</TitleForm>
        <InputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('Register.passwordLabel')}
          />
          <IconButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            {showPassword ? <EyeInvisibleOutlined style={{ fontSize: '18px'}} /> : <EyeOutlined style={{ fontSize: '18px'}} />}
          </IconButton>
        </InputWrapper>
      </Form>

      <Checkbox style={{marginBottom: 5}} checked={agreed} onChange={() => setAgreed(!agreed)}>
        <SubTextBold>{t('Register.agreeLabel_part_1')}{' '}
          <SubTextBlue onClick={() => openNewTab(URL_TERMS)}>{t("Register.agreeLabel_part_2")}{' '}</SubTextBlue> 
          {t('Register.agreeLabel_part_3')} {' '}
          <SubTextBlue onClick={() => openNewTab(URL_PRIVACY)}>{t("Register.agreeLabel_part_4")}</SubTextBlue> 
        </SubTextBold>
      </Checkbox>

      <RegisterButton 
          onClick={handleSubmit} 
          disabled={email.length === 0 || firstName.length === 0 || lastName.length === 0 || password.length === 0 || !agreed}
        >
          {loading ? <Audio stroke={redText} height="18px" /> : t('Register.createAccountLabel')}
      </RegisterButton>
      
      {error && typeError === "E_USER_EXIST" ? (
        <ErrorText>{t('Register.errorEmailAlready')}</ErrorText>
      ) : (
        error && <ErrorText>{t('Register.errorForm')}</ErrorText>
      )}


      {/*
      <Separator>
        {t('Register.orContinue')}
      </Separator>

      <GoogleButton />
      */}

    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Logo = styled.img`
  width: 45px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #808EAF44;
  border-radius: 10px;
  font-size: 14px;
  padding-right: 40px;
  margin-bottom: 10px;
`;

const IconButton = styled.button`
  position: absolute;
  right: 10px;
  top: 13px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #808EAF;

  &:hover {
    color: #666;
  }
`;

const BlocText = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SubText = styled.span`
  font-size: 13px;
  color: #808EAF;
`;

const SubTextBold = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const SubTextRed = styled.span`
  font-weight: 600;
  color: ${redText};
  cursor: pointer;

  &:hover {
    color: #e55e5e;
  }
`;

const SubTextBlue = styled.span`
  font-weight: 600;
  color: #1990FF;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const Form = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const RegisterButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FF6259;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;

  &:hover {
    background-color: #EE6259;
  }
`;

const TitleForm = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: #ff6b6b;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0px 20px 0px;
  font-weight: 500;
  color: #808EAF;
  
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 1.5px solid #808EAF44;
    margin: 0 10px;
  }
`;

export default StartSignUpScreen;