import React from 'react';
import styled from 'styled-components';

interface Props {
  children: string;
  color?: string;
  bold?: boolean;
  align?: 'center' | 'right' | 'left';
  [arg: string]: any;
};

const Title: React.FC<Props> = ({children, color, bold, align = "left", ...rest}) => (
    <Container align={align} color={color} bold={bold} {...rest}>{children}</Container>
);

const Container = styled.p<{color?: string, bold?: boolean, align: 'center' | 'right' | 'left'}>`
  font-family: 'AvenirBlack';
  color: ${({color}) => color ? color : 'black'};
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  font-size: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  letter-spacing: 1px;
  text-align: ${({align}) => align}; 
`;

export default Title;