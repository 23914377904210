export function noop():undefined { return undefined};

export function getWidthOrHeight(value: number | string | undefined): string {
  if(value) {
    if(typeof value === 'string') {
      return value;
    }
    if(typeof value === 'number') {
      return `${value}px`;
    }
  }
  return '100px';
}

export function delay(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time)
  })
}

export async function getTokenInfos(): Promise<any | null> {
  const tokenInfosString: string | null = localStorage.getItem('@tokenInfos');
  if(tokenInfosString) {
    const tokenInfos = JSON.parse(tokenInfosString);
    return tokenInfos;
  }
  return null;
}

export function getNowWithFormat(): string {
  const now = new Date();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const year = now.getFullYear();
  const hours = now.getHours();
  const mins = now.getMinutes();
  return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} ${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
}

export function truncate(str: string, n: number): string{
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

export function transformHourStringtoDate(hour: string): Date {
  const date = new Date();
  const h = Number(hour.split(':')[0]);
  const m = Number(hour.split(':')[1]);
  const s = Number(hour.split(':')[2]);
  date.setHours(h, m, s);
  return date;
}

export function transformDatetoHourString(date: Date): string {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:00`;
}

export function getDecimalFromMinutes(min: number): number {
  return min / 60;
}