import React, {useMemo} from 'react';
import styled from 'styled-components';
import i18n from '../../utils/i18n';

import {BusinessType} from '../../services/SoundSuitServiceTypes';
import {idToLabel, IdToLabel} from '../../config/hardData';
import design_store from '../../assets/images/designStore.jpg';
import bar from '../../assets/images/bar.jpg';
import event from '../../assets/images/event.jpg';
import hotel from '../../assets/images/hotelSLobby.jpg';
import work_place from '../../assets/images/workplace.jpg';
import medical_practice from '../../assets/images/medicalPractice.jpg';
import restaurant from '../../assets/images/restaurant.jpg';
import wellness_spa from '../../assets/images/wellnessSpa.jpg';
import cafe from '../../assets/images/coffeeShop.jpg';
import fashion_boutique from '../../assets/images/fashionStore.jpg';
import fintess_studio from '../../assets/images/gym.jpg';
import hair_salon from '../../assets/images/beautySalon.jpg';
// import components
import CardOption from './CardOption';

interface Option {
  id: BusinessType;
  image: string;
}

const options: Option[] = [
  {id: 'design_store', image: design_store},
  {id: 'bar', image: bar},
  {id: 'event', image: event},
  {id: 'hotel', image: hotel},
  {id: 'work_place', image: work_place},
  {id: 'medical_practice', image: medical_practice},
  {id: 'restaurant', image: restaurant},
  {id: 'wellness_spa', image: wellness_spa},
  {id: 'cafe', image: cafe},
  {id: 'fashion_boutique', image: fashion_boutique},
  {id: 'fintess_studio', image: fintess_studio},
  {id: 'hair_salon', image: hair_salon},
];

interface Props {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<BusinessType>>;
}

const TypeOptions: React.FC<Props> = props => {

  const {selected, setSelected} = props;

  const lang = i18n.language;
  const currentIdToLabel: IdToLabel = useMemo(() => idToLabel(), [lang]);

  return (
    <WrapperOptions>
      {options.map(({id, image}) => (
        <CardOption
          key={id}
          label={currentIdToLabel[id]}
          image={image}
          selected={selected === id}
          handlePress={() => setSelected(id)}
        />
      ))}
    </WrapperOptions>
  );
};

const WrapperOptions = styled.div<{device: string}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
`;

export default TypeOptions;
