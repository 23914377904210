import './App.css';
import { createGlobalStyle } from 'styled-components';
import { setLogger, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
} from "react-router-dom";

// import fontAvenirNormal from './assets/fonts/Avenir-normal.otf';
import fontAvenir from './assets/fonts/Avenir-black.otf';
import fontAvenirMedium from './assets/fonts/Avenir-medium.otf';
import { queryClient } from './store/reactQueryInitial';
// import components
import MainLayout from './layout/MainLayout';

// React-Query Settings
setLogger({
  log: console.log,
  warn: console.warn,
  error: console.warn
});

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AvenirBlack';
    src: url(${fontAvenir});
  }
  @font-face {
    font-family: 'AvenirMedium';
    src: url(${fontAvenirMedium});
  }
  body {
    margin: 0;
    padding:0;
  }
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
`;

function App() {

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <GlobalStyle />
          <MainLayout />
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
