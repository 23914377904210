import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../store';
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";
// import Components
import ButtonText from '../atoms/ButtonText';
import { redLightBG } from '../../config/colors';
import { CheckOutlined } from '@ant-design/icons';

interface Props {
};

interface Params {
  email?: string;
};

const RenewPassword: React.FC<Props> = props => {

  function handleRedirectionApp() {
    if (from === 'android') {
      window.location.replace(process.env.REACT_APP_BACK_URL_ANDROID);     
    } else if ( from === 'ios') {
      window.location.replace(process.env.REACT_APP_BACK_URL_IOS); 
    } else if ( from ===  'loxone') {
      window.location.replace(process.env.REACT_APP_BACK_URL_LOXONE); 
    } 
  }

  const { state }: {state: Params} = useLocation();
  const {
    email
  } = state || {};

  const from = useStore(state => state.from);

  const [t] = useTranslation();

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title align="center">{t('Auth.thankYouTitle')}</Title>
      <Square>
        <CheckOutlined style={{ fontSize: '38px', margin: 'auto', color: redLightBG }} />
      </Square>
      <Bloc2>
        <Description>We just sent an email to this address</Description>
        <UserEmail align='center'>{email}</UserEmail>
        <Description>Open the email and click on the "Reset password" button. Follow the instructions and come back here to log in.</Description>
        <WrapperButton>
        {["ios", "android", "loxone"].includes(from) && (
        <ButtonText
          onHandleClick={handleRedirectionApp}
          colorType='red'
          width="100%"
          height={50}
          borderRadius="big"
          fontSize={12}
        >
          {t('Register.buttonRedirect')}
        </ButtonText>
      )}
        </WrapperButton>
      </Bloc2>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
`;

const Square = styled.div`
  width: 100px;
  height: 100px;
  background-color: #F9EDED;
  border-radius: 25px;
  display: flex;
  margin-bottom: 50px;
`;

const Bloc2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
`;

const UserEmail = styled.span`
  color: #007aff;
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 0px;
`;

const WrapperButton = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export default RenewPassword;