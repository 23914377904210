import React from 'react';
import styled from 'styled-components';

interface Props {
  children: string;
  color?: string;
  bold?:boolean;
  size?: 'small' | 'xsmall' | 'normal' | 'large' | 'verysmall';
  align?: 'center' | 'right' | 'left';
  [arg: string]: any;
}

const Text: React.FC<Props> = props => {
  const {
    children,
    color,
    size = 'normal',
    align = "left",
    ...rest
  } = props;
  return (
    <Container align={align} color={color} size={size} {...rest}>{children}</Container>
  );
}

const Container = styled.span<{color?: string, bold?: boolean, size: 'small' | 'xsmall' | 'normal' | 'large' | 'verysmall', align: 'center' | 'right' | 'left'}>`
  font-family: ${({bold}) => bold ? 'AvenirBlack': 'AvenirMedium'};
  color: ${({color}) => color ? color : 'black'};
  line-height: ${({size}) => {
    if (size === 'verysmall') {
      return '8px';
    }
    if (size === 'small') {
      return '12px';
    }
    if (size === 'xsmall') {
      return '15px';
    }
    if (size === 'large') {
      return '20px';
    }
    return '18px';
  }};
  font-size: ${({size}) => {
    if (size === 'verysmall') {
      return '8px';
    }
    if (size === 'small') {
      return '12px';
    }
    if (size === 'xsmall') {
      return '15px';
    }
    if (size === 'large') {
      return '20px';
    }
    return '18px';
  }};
  text-align: ${({align}) => align};
`;

export default Text;